import { BadgeValue } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React, { FC } from 'react';

import { useFormattedPrice } from '@Components/Price/useFormattedPassengerPrice';

type DepositSashProps = {
  lowestDeposit: number;
};

export const DepositSash: FC<DepositSashProps> = ({ lowestDeposit }) => {
  const { t } = useTranslation();

  const formattedPassengerPrice = useFormattedPrice({
    value: lowestDeposit,
    suffixType: 'short',
  });

  return (
    <BadgeValue
      category="Deposit"
      data-id="prices-deposit"
      sx={{
        paddingX: '2xs',
        backgroundColor: 'backgroundPrimarylight',
        letterSpacing: '-0.04em',
        '> span': {
          fontWeight: 'normal',
          gap: 0,
        },
        whiteSpace: 'nowrap',
      }}
    >
      {formattedPassengerPrice} {t('pricing.deposit')}
    </BadgeValue>
  );
};
