import { Sash } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React from 'react';

export type PercentageOffSashProps = {
  discountPercentage: number;
};

export const PercentageOffSash: React.FC<PercentageOffSashProps> = ({ discountPercentage }) => {
  const { t } = useTranslation();

  return (
    <Sash
      data-id="prices-discount"
      variant="Promotion"
      sx={{
        paddingX: '2xs',
        letterSpacing: '-0.04em',
        '> span': {
          fontWeight: 'normal',
        },
      }}
    >
      {`${discountPercentage}% ${t('pricing.belowPeak')}`}
    </Sash>
  );
};
